import React from "react";
import { useDispatch } from "react-redux";
import AlertPasskey from "components/alert-passkey-view";
import { meRequestPost } from "modules/me-service-module";

export const AlertPasskeyContainer = () => {
  const dispatch = useDispatch();
  const handleAlertDismiss = () => {
    const currentDate = Date.now();
    const payload = {
      query: `mutation { updateUser(input: { passKeyBannerClosedAt: "${currentDate}" }) }`
    };
    dispatch(meRequestPost(payload));
  };

  return (<AlertPasskey handleDismiss={handleAlertDismiss} />);
};

export default AlertPasskeyContainer;