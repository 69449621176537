import React from "react";
import PropTypes from "prop-types";
import Alert from "components/alert-view";
import { defineMessages, FormattedHTMLMessage } from "react-intl";
import appconfig from "config/appconfig";
import { Typography } from "@getgo/chameleon-web-react-wrapper";
import { PasskeyOutlinedIcon } from "@getgo/chameleon-icons/react";
import classes from "./alert.scss";

const t = defineMessages({
  "alert.passkey-signin.description": {
    id: "alert.passkey-signin.description",
    defaultMessage: "Use a passkey to access GoTo in a more convenient and secure way. <a class =\"learnMoreLink\" href={aboutPasskey} rel=\"noopener noreferrer\" target=\"_blank\">Learn more</a>",
  },
});

export const AlertPasskeyView = ({ handleDismiss }) => (
  <Alert
    type={appconfig.alertTypes.WARNING}
    icon={<PasskeyOutlinedIcon />}
    body={
      <span className={classes.bannerMessageText}>
        <Typography>
          <FormattedHTMLMessage
            {...t["alert.passkey-signin.description"]}
            values={{ aboutPasskey: "https://support.goto.com/connect/help/create-and-manage-passkeys-for-sign-in" }} />
        </Typography>
      </span>
    }
    handleDismiss={handleDismiss}
  />
);

AlertPasskeyView.propTypes = {
  alertVisisble: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired
};

export default AlertPasskeyView;
